import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bx-footer',
  templateUrl: './bx-footer.component.html',
  styleUrls: ['./bx-footer.component.scss']
})
export class BxFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
