// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://dev-aoo.eastus.cloudapp.azure.com:8090/',
  appUrl: 'https://dev-orders.mohawkgroup.com',
  auth: {
    clientId: 'd1d720b9-274c-46f4-8ab7-613ce58224bb',
    authority: 'https://login.microsoftonline.com/04eca944-6c51-44b3-968f-d0b53acd3878', // This is your tenant ID
    knownAuthorities: ['https://login.microsoftonline.com/04eca944-6c51-44b3-968f-d0b53acd3878'],
  },
  onDemand: {
    virtualServicesBaseUrl: "https://pubqas.virtualservices.mohawkind.com/",
    virtualBaseStartegicUrl:"https://pubqas.virtualservices.mohawkind.com/SOAQ405/vPUB.MHK.SAP.SalesCloud.OData.svc/CorporateAccountCollection",
    virtualServicesQuoteNoOpportunityStrategicUrl: "SOAP105/vPUB.MHK.SAP.SalesCloudBA.OData.svc/RPZFC98739381BD60F9088BB7QueryResults?$select=CWRKADRS_EMAIL,CEMPLOYEMBERS51D54E5A4353505B,CZDISTREMBERS8ED3E7372C8673BE,KCSPLITPEMBERSE2BE0A631F9A0836,KCZTEAMSEMBERS17624649FAFE8B86,CDOC_PRED_OPPH,CEMPLOYEMBERS989827D4C470F56D,CZDISTREMBERS678CD4A54A6EB3B3,FCZ0COUNT&$filter=(CDOC_BUYER_ID eq '{params}')&$format=json",
    virtualServicesQuoteNoStrategicUrl: "SOAQ405/vPUB.MHK.SAP.SalesCloudBA.OData.svc/RPZ8BA6F9BED9E9F01B16EA52QueryResults?$select=CWRKADRS_EMAIL,CEXTERNAL_ID,CREMOTE_OBJECT_ID,CDOC_BUYER_ID,CDOC_ID_01,TDOC_ID_01,TDPY_PARTY_ROLE,TDPY_PARTY_UUID,CDOC_ID,KCZ982127BE30D6A2A1F5E535&$filter=(CDOC_BUYER_ID eq '{params}')&$format=json",
    virtualServicesQuoteWithStrategicAcctUrl: "SOAQ405/vPUB.MHK.SAP.SalesCloudBA.OData.svc/RPZ2EB47E3742A5AA22A133BDQueryResults?$select=CREMOTE_OBJECT_ID,CDOC_BUYER_ID,CWRKADRS_EMAIL,CEE_ID,TDPY_PARTY_ROLE,TDPY_PARTY_UUID,Ts1ANs00F4056E3F616B1,CDOC_ID,TDOC_ID,CDBA_TERRITORY_ID,TDBA_TERRITORY,CYNNN29NPY_C7E96CD677,CEXTERNAL_ID&$filter=(CDOC_BUYER_ID eq '{params}')&$format=json",
    virtualServicesStrategicAcctNowithoutquoteUrl: "SOAQ405/vPUB.MHK.SAP.SalesCloudBA.OData.svc/RPZC8BB9212858432AFEED419QueryResults?$select=Ts1ANs00F4056E3F616B1&$filter=(CYNNN29NPY_C7E96CD677 eq '{params}')&$format=json",
    username: 'eMpower_hybris',
    password: 'Gr3@tJo8!'
  }

}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
