import { Component, Inject, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './features/http-services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'aoo-frontend';
  isIframe = false;
  loginDisplay = false;
  result: any[] = [];
  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private storageService: StorageService) { }

  ngOnInit() {
    if (typeof window != "undefined") {
      this.isIframe = window !== window.parent && !window.opener;

    }
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() { // Add log out function here
    this.authService.logoutRedirect({
      // postLogoutRedirectUri: 'http://localhost:4200'
      postLogoutRedirectUri: window.location.origin
      // postLogoutRedirectUri: environment.appUrl
    });
  }

  setLoginDisplay() {
    this.result = this.authService.instance.getAllAccounts();
    console.log("this.resultssss------>",this.result)


   // const result1 = this.result[0]?.idTokenClaims;
    // const result1 = this.result[0]?.idTokenClaims?.verified_secondary_email.filter((item: any) => {

    //   return (item || "").includes("@mohawkllc.onmicrosoft.com")
    
    // })


   // console.log("this.authService.instance.getAllAccounts()--->", this.result[0],result1);
   // let emp = result1[0];
   // emp = emp.split('@')[0]?.substring(1);
   
    //testing purpose
    //let TM = 48301, RVP = 98308, SVP = 34013, Sales_ops= 127033, Finance = 11111; 
    // this.storageService.clear();
    let email = this.result[0]?.idTokenClaims?.email;
    this.storageService.setItem("empEmail", email);
  //  this.storageService.setItem("empEmail", 'savitha_nuguri@mohawkind.com');
  //  this.storageService.setItem("empEmail", 'lina_rey@mohawkind.com');
   // this.storageService.setItem("employeId", emp);

  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}
