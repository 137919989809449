import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { StorageService } from 'src/app/features/http-services/storage.service';

@Component({
  selector: 'bx-header',
  templateUrl: './bx-header.component.html',
  styleUrls: ['./bx-header.component.scss']
})
export class BxHeaderComponent implements OnInit {

  constructor(private authService: MsalService, private storageService: StorageService) { }

  ngOnInit(): void {
  }

  logout() { // Add log out function here
    this.storageService.clear();
    localStorage.clear();
    this.authService.logoutRedirect({
      // postLogoutRedirectUri: 'http://localhost:4200'
      postLogoutRedirectUri: window.location.origin
      // postLogoutRedirectUri: environment.appUrl
    });
  }
  menuRight: Array<any> = [
    {
      name: 'Interior Specialist Inc',
      icon: true,
      link: '',
    },
    {
      name: 'Account # R.100430.0000',
    },
    {
      name: 'Creative Touch/HD Supply • P.O. Box 61929 • Irvine, CA 92602',
    }
  ]

}
