<!-- Site Top Bar  -->
<section class="top-bar-section">
    <div class="container">
        <div class="mobile-top-bar">
            <div class="d-flex justify-content-center">
                <ul class="toggle-project">
                    <li class="active" style="cursor:default">
                        ORDER AUTOMATION
                    </li>
                </ul>
            </div>
            <!-- <div class="mobile-top-right-menu">
                <button class="btn btn-primary">Dashboard</button>
                <button>
                    <span class="mohawak-icon user-icon"></span>
                </button>
            </div> -->
        </div>
        <div class="top-bar">
            <ul class="top-bar-menu-left">
                <li>
                    <a class="active" style="cursor:default"> ORDER AUTOMATION </a>
                </li>
            </ul>
        </div>
    </div>
</section>

<header class="header-section">
    <div class="container">
        <header class="site-header">
            <div class="site-logo-and-search-container">

                <div class="logo" routerLink="/dashboard" style="cursor:pointer">
                    <img src="/assets/images/mg-logo.svg" alt="" routerLink="/dashboard">
                </div>

            </div>
            <div class="site-header-cart-container dropdown">
                <!-- <button class="btn btn-primary xs m-2 dashboard-cta"  routerLink="/table">Dashboard table </button> -->
                
                <button class="btn btn-primary xs m-2 dashboard-cta"  routerLink="/dashboard">Dashboard</button>
                <div class="me-2 dropdown" style="cursor:pointer">
                    <img src="/assets/images/icons/user1.svg" class=" dropdown-toggle" type="button" data-toggle="dropdown">
                    <ul class="dropdown-menu">
                        <li class='logout'><a  (click)="logout()">LOG OUT</a></li>
                    </ul>
                </div>
            </div>
        </header>
    </div>
</header>