import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizedLayoutComponent } from './authorized-layout/authorized-layout.component';
import { BxFooterComponent } from './components/bx-footer/bx-footer.component';
import { BxHeaderComponent } from './components/bx-header/bx-header.component';
import { UnauthorizedLayoutComponent } from './unauthorized-layout/unauthorized-layout.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    BxHeaderComponent,
    BxFooterComponent,
    AuthorizedLayoutComponent,
    UnauthorizedLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    BxHeaderComponent,
    BxFooterComponent,
    AuthorizedLayoutComponent,
    UnauthorizedLayoutComponent
  ]
})
export class LayoutsModule { }
