import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthorizedLayoutComponent } from './features/shared/layouts/authorized-layout/authorized-layout.component';
let isIframe
if (typeof window != "undefined") {
  isIframe = window !== window.parent && !window.opener;

}

const routes: Routes = [
  // {
  //   path: '', redirectTo: '/home', pathMatch: 'full'
    
  // },
  // {
  //   path: '',
  //   component: UnauthorizedLayoutComponent,
  //   canActivate: [MsalGuard],
  //   loadChildren: () => import('./features/user/user.module').then(m => m.UserModule),
  // },
  {
    path: 'dashboard',
    component: AuthorizedLayoutComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: '',
    component: AuthorizedLayoutComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
  }
];

// const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled' 
  })],
  // imports: [RouterModule.forRoot(routes, {
  //  initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  // })],

//  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
