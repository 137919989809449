<footer class="footer-section">
    <div class="container">
        <div class="footer-content">
            <div>
                <ul class="top-bar-menu-right footer-menu">
                    <li> <a  target="_blank" href="https://www.mohawkgroup.com/legal-notice"> Legal Notice </a></li>
                    <!-- <li> <a> Terms and Conditions </a></li> -->
                    <li> <a target="_blank" href="https://mohawkind.com/privacy-policy.php"> Privacy Policy </a></li>
                    <!-- <li> <a> Contact Us </a></li> -->
                </ul>
                <p class="copy-right-text mt-2 mb-0">© 2024 Mohawk Industries, Inc.
                </p>
            </div>


            <div>
                <img class="footer-logo" src="/assets/images/mg-logo.svg" alt="">
            </div>


        </div>
    </div>
</footer>